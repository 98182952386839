import "./Positions.css";
import React, { useState } from "react";
import { useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import Dashboard from "pages/Dashboard/Dashboard";
import { getPageTitle, isHashZero } from "lib/legacy";
import { getApiUrl } from "config/backend";
// import {
//   useReferralsData,
//   useCodeOwner,
//   useReferrerTier,
//   useUserReferralCode,
// } from "domain/referrals";
import LoginCard from "components/Positions/LoginCard";
import PayWall from "components/PayWall/PayWall";
import { ethers } from "ethers";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getIcon } from "config/icons";

import decode from "jwt-decode";

function Positions({ connectWallet, getUserJWT, setUserJWT, positions, setPositions }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { chainId } = useChainId();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }

  const { data: fetchedPositions } = useSWR([`${getApiUrl("positions")}`, getUserJWT], {
    fetcher: (url, token) =>
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok){
            throw new Error("lol")
          }
          return res.json()
        })
        .then((res) => setPositions(res))
        .catch((err) => {
          console.log(err)
        }),
    refreshInterval: 60000
  });
  // console.log(positions)

  // const { loading } = useReferralsData(chainId, account);
  let loading = false;
  const networkIcon = getIcon(chainId, "network");

  function renderPositions() {
    if (loading) return <Loader />;
    let decoded;
    try {
      decoded = decode(getUserJWT);
      // console.log(decoded)
      if (decoded.exp * 1000 < Date.now()) {
        decoded = null;
        setUserJWT(null);
        throw new Error("EXPIRED_TOKEN");
      }
    } catch (err) {}
    // console.log(decoded)
    if (!account || !decoded?.address) {
      return (
        <LoginCard
          connectWallet={connectWallet}
          active={active}
          getUserJWT={getUserJWT}
          setUserJWT={setUserJWT}
        />
      );
    }
    if (!positions){
      return (
        <PayWall/>
      )
    }
    return (
      <Dashboard getUserJWT={getUserJWT} setUserJWT={setUserJWT} setPositions={setPositions} positions={positions} />
    );
  }

  return (
    <SEO title={getPageTitle("Positions")}>
      <div className="default-container page-layout Referrals">
        <div className="section-title-block">
          <div className="section-title-icon" />
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>
                Positions <img width="24" src={networkIcon} alt="Network Icon" />
              </Trans>
            </div>
            <div className="Page-description">
              <Trans>
                Define specific risk parameters used to pull liquidity to safety
                <br />
                For more information, please read the{" "}
                <ExternalLink href="https://0x27.gitbook.io/0x27/positions">position documentation</ExternalLink>.
              </Trans>
            </div>
          </div>
        </div>
        {renderPositions()}
      </div>
      <Footer />
    </SEO>
  );
}

export default Positions;
