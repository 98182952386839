import React, { useCallback, useState, useMemo } from "react";

import Modal from "../Modal/Modal";

import useSWR from "swr";

import ExchangeInfoRow from "./ExchangeInfoRow";
import { Plural, t, Trans } from "@lingui/macro";
import { numberWithCommas, limitDecimals, padDecimals, formatAmount, expandDecimals } from "lib/numbers";
import { helperToast } from "lib/helperToast";
import { getTxorApiUrl } from "config/backend";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import { ARBITRUM, ETH_MAINNET } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { getContract } from "config/contracts";

import "./OpenPosition.css";
// import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";


import Token from "abis/Token.json";


function protocolSelector(project) {
  if (project === "convex-finance") {
    return "Convex"
  }
  if (project === "chronos") {
    return "Chronos"
  }
  return "nothing"
}


function strategySelector(project){
  if (project === "convex-finance"){
    return "ethConvex"
  }
  if (project === "chronos"){
    return "arb1Chronos"
  }
  return "nothing"
}

export default function OpenPosition(props) {
  const { account, library, active } = useWeb3React();

  const { chainId } = useChainId();
  const { setIsOpening, position, setPositions, getUserJWT, positions } = props;

  const lusdAddress = getContract(ARBITRUM, "LUSD");
  const usdceAddress = getContract(ARBITRUM, "USDCe");

  const { data: lusdBalance } = useSWR(
    [`arbitrum:lusd:balance:${active}`, ARBITRUM, lusdAddress, "balanceOf", positions?.safe_address],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: usdcBalance } = useSWR(
    [`arbitrum:usdce:balance:${active}`, ARBITRUM, usdceAddress, "balanceOf", positions?.safe_address],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  // console.log("usdcBalance", usdcBalance)
  // console.log("lusdBalance", lusdBalance)

  // const { data: depositBalances } = useSWR(
  //   [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: contractFetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
  //   }
  // );

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  // @todo make sure chainid is arbitrum for chronos
  // console.log(position)
  const getTitle = () => {
    return t`Open ${position?.symbol} Position`;
  };
  const title = getTitle();
  const [isSaving, setIsSaving] = useState(false);

  let headers = { "Content-Type": "application/json" };
  if (getUserJWT) {
    headers.Authorization = "Bearer " + getUserJWT;
  }
  const defaultVal = "0.99999"; 
  const [stopLossValue, setStopLossValue] = useState(position?.stop?.stoploss || defaultVal);
  //@todo error handling
  const onConfirmationClick = () => {
    if (chainId !== ARBITRUM && strategySelector(position.project).includes("arb1")) {
      return changeNetwork(ARBITRUM);
    }
    if (chainId !== ETH_MAINNET && strategySelector(position.project).includes("eth")) {
      return changeNetwork(ETH_MAINNET);
    }

    let minLUSD = expandDecimals(1, 18)
    let minUSDC = expandDecimals(2, 6)
    if (usdcBalance.lt(minUSDC)) {
      return null;
    }

    setIsSaving(true)
    if (lusdBalance.lt(minLUSD)) {
      return swapUSDC()
    }
    return setNFTPosition()
  };

  const swapUSDC = () => {
    return fetch(`${getTxorApiUrl("swap_usdc")}`, {
      headers,
      method: "PUT",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res)
        return library.getSigner().sendTransaction({ data: res.data, to: res.to })
      })
      .then((res) => {
        setIsSaving(false)
        helperToast.success(t`1 USDC -> LUSD Tx Sent.`);
      })
      .catch((err) => {
        // console.log(err)
        setIsSaving(false)
        helperToast.error(t`Could not swap USDC.`);
      })
  }

  const setNFTPosition = () => {
    return fetch(`${getTxorApiUrl("open_position")}`, {
      headers,
      method: "PUT",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res)
        return library.getSigner().sendTransaction({ data: res.data, to: res.to })
      })
      .then((res) => {
        setIsSaving(false)
        setIsOpening(false)
        helperToast.success(t`Open Position Tx Sent.`);
      })
      .catch((err) => {
        // console.log(err)
        setIsSaving(false)
        setIsOpening(false)
        helperToast.error(t`Could not open the position.`);
      })
  }


  const isPrimaryEnabled = () => {
    // const [error] = getError();
    // if (error) {
    //   return false;
    // }
    if (isSaving) {
      return false;
    }

    if (!usdcBalance || !lusdBalance) {
      return false
    }

    let minUSDC = expandDecimals(2, 6)
    // if not enoough USDC, tell them to deposit more
    if (usdcBalance.lt(minUSDC)) {
      return false
    }
    // if lusdBalance is higher than 1 & usdc is higher than 2

    return true;
  };

  const getPrimaryText = () => {
    // const [error] = getError();
    // if (error) {
    //   helperToast.error(t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
    //   return error;
    // }

    if (isSaving) {
      return t`Transacting...`;
    }
    if (chainId !== ARBITRUM && strategySelector(position.project).includes("arb1")) {
      return t`Switch to Arbitrum`;
    }
    if (chainId !== ETH_MAINNET && strategySelector(position.project).includes("eth")) {
      return t`Switch to Ethereum`;
    }

    if (!usdcBalance || !lusdBalance) {
      return t`Loading...`
    }
    let minLUSD = expandDecimals(1, 18)
    let minUSDC = expandDecimals(2, 6)
    // if not enoough USDC, tell them to deposit more
    if (usdcBalance.lt(minUSDC)) {
      return t`Deposit More USDC`
    }
    // if lusdbalance is less than 1 buy LUSD
    if (lusdBalance.lt(minLUSD)) {
      return t`Buy LUSD using 1 USDC`
    }
    // if lusdBalance is higher than 1 & usdc is higher than 2

    return t`Open Position`;
  };

  return (
    <div className="Confirmation-box">
      <Modal isVisible={true} setIsVisible={() => setIsOpening(false)} label={title} allowContentTouchMove>
        {/* {isSwap && renderSwapSection()} */}
        {/* {!isSwap && renderMarginSection()} */}
        <ExchangeInfoRow label={t`Asset`}>
          {position.symbol}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`Protocol`}>
          {protocolSelector(position.project)}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        {/* <ExchangeInfoRow label={t`Peg Price`} isTop={true}> */}

          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        {/* </ExchangeInfoRow> */}
        <ExchangeInfoRow label={t`LUSD Balance`} isTop={true}>
          {/* formatAmount(fraxbp?.data[0].amount, DAI_DECIMALS, 0, true) */}
          {lusdBalance ? formatAmount(lusdBalance, 18, 2, true) : "0"}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`USDC Balance`}>
          {usdcBalance ? formatAmount(usdcBalance, 6, 2, true) : "0"}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        {/* <ExchangeInfoRow label={t`LP Balance`}>
          {position.convex?.userStaked ? numberWithCommas(limitDecimals(position.convex?.userStaked, 2)) : "0"}
        </ExchangeInfoRow> */}

        <ExchangeInfoRow isTop={true}>
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>

        {/* <div className="App-settings-row">
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              min="0"
              max="2"
              value={stopLossValue}
              onChange={(e) => setStopLossValue(e.target.value)}
            />
          </div>
        </div> */}
        <div className="Confirmation-box-row">
          {/* <Link to="/nothing" className="default-btn" onClick={() => changeNetwork(ARBITRUM)}>
            Switch to Arbitrum
          </Link> */}
          <button
            onClick={onConfirmationClick}
            className="App-cta Confirmation-box-button"
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
