import React, { useCallback, useState, useMemo } from "react";

import Modal from "../Modal/Modal";

import ExchangeInfoRow from "./ExchangeInfoRow";
import { Plural, t, Trans } from "@lingui/macro";
import { numberWithCommas, limitDecimals, padDecimals } from "lib/numbers";
import { helperToast } from "lib/helperToast";
import Checkbox from "components/Checkbox/Checkbox";
import { getApiUrl } from "config/backend";

import "./ShutdownPosition.css";

function protocolSelector(project) {
  if (project === "convex-finance") {
    return "Convex"
  }
  if (project === "chronos") {
    return "Chronos"
  }
  return "nothing"
}

export default function ShutdownPosition(props) {
  const { setIsShutting, position, setPositions, getUserJWT } = props;
  // console.log(position)
  const getTitle = () => {
    // if (!isMarketOrder) {
    //   return t`Confirm Limit Order`;
    // }
    // if (isSwap) {
      return t`Close ${position?.symbol} Position`;
    // }
    // return isLong ? t`Confirm Long` : t`Confirm Short`;
  };
  const title = getTitle();
  const [isSaving, setIsSaving] = useState(false);

  let headers = { "Content-Type": "application/json" };
  if (getUserJWT) {
    headers.Authorization = "Bearer " + getUserJWT;
  }
  const [shutdownValue, setShutdownValue] = useState(position?.stop?.shutdown || false);
  const [agreeShutdown, setAgreeShutdown] = useState(false);
  //@todo error handling
  const onConfirmationClick = () => {
    setIsSaving(true)
    return fetch(`${getApiUrl("position/shutdown")}`, {
      headers,
      method: "PUT",
      body: JSON.stringify({ pid: position.stop.pid, strategy_id: position.stop.strategy_id }),
    })
    // .then((result) => result.text())
    .then((res) => fetch(`${getApiUrl("positions")}`, { headers }))
    .then((res) => res.json())
    .then((res) => {
      setPositions(res)
      setIsSaving(false)
      setIsShutting(false)
      helperToast.success(t`Position shutting down...`);
    })
    .catch((err) => {
      // console.log(err)
      setIsSaving(false)
      setIsShutting(false)
      helperToast.error(t`Could not shutdown the position.`);
    })

  };


  const isPrimaryEnabled = () => {
    // const [error] = getError();
    // if (error) {
    //   return false;
    // }
    if (isSaving) {
      return false;
    }
    if (!agreeShutdown) {
      return false;
    }
    if (shutdownValue){
      return false
    }

    return true;
  };

  const getPrimaryText = () => {
    // const [error] = getError();
    // if (error) {
    //   helperToast.error(t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
    //   return error;
    // }
    // if (isSwapping) {
    //   if (isDeposit) {
    //     return t`Depositing...`;
    //   }
    //   return t`Withdrawing...`;
    // }
    if (shutdownValue){
      return t`Already Queued`;
    }

    if (isSaving) {
      return t`Saving...`;
    }

    return t`Close Position`;
  };

  return (
    <div className="Confirmation-box">
      <Modal isVisible={true} setIsVisible={() => setIsShutting(false)} label={title} allowContentTouchMove>
        {/* {isSwap && renderSwapSection()} */}
        {/* {!isSwap && renderMarginSection()} */}
        <ExchangeInfoRow label={t`Asset`}>
          {position.symbol}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`Protocol`}>
          {protocolSelector(position.project)}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`NAV`}>
          ${position.convex?.userStaked ? numberWithCommas(limitDecimals(position.convex?.userStaked, 2)) : 0}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        {/* <ExchangeInfoRow label={t`Peg Price`} isTop={true}> */}

          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        {/* </ExchangeInfoRow> */}
        <ExchangeInfoRow label={t`LP tokens`} isTop={true}>
          {position.convex?.stakedTokens} {position.convex?.stakeTokenTicker}
        </ExchangeInfoRow>
        {/* <ExchangeInfoRow label={t`Estimated Return`}>
          100 ETH
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`Estimated Tx Cost`}>
          0.1 ETH
        </ExchangeInfoRow> */}

        <ExchangeInfoRow isTop={true}>
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>

        <div className="App-settings-row">
          {/* <div>
            <Trans>Allowed Slippage</Trans>
          </div> */}
          <div className="Exchange-settings-row">
            <Checkbox isChecked={agreeShutdown} setIsChecked={setAgreeShutdown}>
              <Trans>I understand that this will queue a transaction to close down the position.</Trans>
            </Checkbox>
          </div>
        </div>
        <div className="Confirmation-box-row">
          <button
            onClick={onConfirmationClick}
            className="App-cta Confirmation-box-button"
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
