import React, { useCallback, useState, useMemo } from "react";

import Modal from "../Modal/Modal";

import ExchangeInfoRow from "./ExchangeInfoRow";
import { Plural, t, Trans } from "@lingui/macro";
import { numberWithCommas, limitDecimals, padDecimals } from "lib/numbers";
import { helperToast } from "lib/helperToast";
import { getApiUrl } from "config/backend";

import "./UpdatePosition.css";
// import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";


function protocolSelector(project) {
  if (project === "convex-finance") {
    return "Convex"
  }
  if (project === "chronos") {
    return "Chronos"
  }
  return "nothing"
}

function strategySelector(project){
  if (project === "convex-finance"){
    return "ethConvex"
  }
  if (project === "chronos"){
    return "arb1Chronos"
  }
  return "nothing"
}

export default function UpdatePosition(props) {
  const { setIsConfirming, position, setPositions, getUserJWT } = props;
  // console.log(position)
  const getTitle = () => {
    // if (!isMarketOrder) {
    //   return t`Confirm Limit Order`;
    // }
    // if (isSwap) {
      return t`Update ${position?.symbol} Parameters`;
    // }
    // return isLong ? t`Confirm Long` : t`Confirm Short`;
  };
  const title = getTitle();
  const [isSaving, setIsSaving] = useState(false);

  let headers = { "Content-Type": "application/json" };
  if (getUserJWT) {
    headers.Authorization = "Bearer " + getUserJWT;
  }
  const defaultVal = "0.99999"; 
  const [stopLossValue, setStopLossValue] = useState(position?.stop?.stoploss || defaultVal);
  //@todo error handling
  const onConfirmationClick = () => {
    setIsSaving(true)
    return fetch(`${getApiUrl("position")}`, {
      headers,
      method: "PUT",
      body: JSON.stringify({ asset: position.symbol, stoploss: stopLossValue, pid: position.pid, active: true, shutdown: false, strategy_id: strategySelector(position.project) }),
    })
    // .then((result) => result.text())
    .then((res) => fetch(`${getApiUrl("positions")}`, { headers }))
    .then((res) => res.json())
    .then((res) => {
      setPositions(res)
      setIsSaving(false)
      setIsConfirming(false)
      helperToast.success(t`Position updated.`);
    })
    .catch((err) => {
      // console.log(err)
      setIsSaving(false)
      setIsConfirming(false)
      helperToast.error(t`Could not save the position.`);
    })

  };


  const isPrimaryEnabled = () => {
    // const [error] = getError();
    // if (error) {
    //   return false;
    // }
    if (isSaving) {
      return false;
    }
    // if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
    //   return false;
    // }
    // if (isPositionRouterApproving) {
    //   return false;
    // }

    return true;
  };

  const getPrimaryText = () => {
    // const [error] = getError();
    // if (error) {
    //   helperToast.error(t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
    //   return error;
    // }
    // if (isSwapping) {
    //   if (isDeposit) {
    //     return t`Depositing...`;
    //   }
    //   return t`Withdrawing...`;
    // }

    if (isSaving) {
      return t`Saving...`;
    }

    return t`Save Stop Loss`;
  };

  return (
    <div className="Confirmation-box">
      <Modal isVisible={true} setIsVisible={() => setIsConfirming(false)} label={title} allowContentTouchMove>
        {/* {isSwap && renderSwapSection()} */}
        {/* {!isSwap && renderMarginSection()} */}
        <ExchangeInfoRow label={t`Asset`}>
          {position.symbol}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`Protocol`}>
          {protocolSelector(position.project)}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        <ExchangeInfoRow label={t`NAV`}>
          ${position.convex?.userStaked ? numberWithCommas(limitDecimals(position.convex?.userStaked, 2)) : 0}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>
        {/* <ExchangeInfoRow label={t`Peg Price`} isTop={true}> */}

          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        {/* </ExchangeInfoRow> */}
        <ExchangeInfoRow label={t`Current Stop Loss`} isTop={true}>
          {position.stop && position.stop.pid ? padDecimals(position.stop.stoploss, 4) : "N/A"}
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>

        <ExchangeInfoRow isTop={true}>
          {/* {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol} */}
        </ExchangeInfoRow>

        <div className="App-settings-row">
          {/* <div>
            <Trans>Allowed Slippage</Trans>
          </div> */}
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              min="0"
              max="2"
              value={stopLossValue}
              onChange={(e) => setStopLossValue(e.target.value)}
            />
            {/* <div className="App-slippage-tolerance-input-percent">%</div> */}
          </div>
        </div>
        <div className="Confirmation-box-row">
          <button
            onClick={onConfirmationClick}
            className="App-cta Confirmation-box-button"
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
