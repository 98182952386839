import { ethers } from "ethers";
import { getContract } from "config/contracts";
import { getProvider } from "lib/rpc";
import { CVX_CHEF_STAKING_ABI, CRV_STAKING_ABI, CRV_CHEF_ABI, GAUGE_ABI, CVX_CHEF_ABI, CVX_REWARDS_STAKING_ABI, SUSHI_CHEF_ABI, CRV_SUSHI_STAKING_ABI, LOCK_CVX_ABI, registryAbi, SUSHI_CHEF_UNSTAKE_ABI, SUSHI_CHEF_CLAIM_ABI } from "abis/Convex"



export function getCrvChefContract(chainId) {
    const address = getContract(chainId, "CRV_CHEF_ADDR");
    const provider = getProvider(undefined, chainId);
    const contract = new ethers.Contract(address, JSON.stringify(CRV_CHEF_ABI), provider);
    return contract;
}

export function getCvxRegistry(chainId) {
    const address = getContract(chainId, "CVX_REGISTRY");
    const provider = getProvider(undefined, chainId);
    const contract = new ethers.Contract(address, JSON.stringify(registryAbi), provider);
    return contract;
}
export function getCvxChefContract(chainId) {
    const address = getContract(chainId, "CVX_CHEF_ADDR");
    const provider = getProvider(undefined, chainId);
    const contract = new ethers.Contract(address, JSON.stringify(CVX_CHEF_ABI), provider);
    return contract;
}

export function getCvxStakingContract(chainId) {
    const address = getContract(chainId, "CVX_CHEF_STAKING_ADDR");
    const provider = getProvider(undefined, chainId);
    const contract = new ethers.Contract(address, JSON.stringify(CVX_CHEF_STAKING_ABI), provider);
    return contract;
}

const chunk = (arr, n) => arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : []

export async function getCoingeckoPrices(id_array: string[]) {
    const prices = {}
    for (const id_chunk of chunk(id_array, 50)) {
        let ids = id_chunk.join('%2C')
        const response = await fetch('https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=' + ids + '&vs_currencies=usd');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const indexPrices = await response.json();
        for (const [key, v] of Object.entries(indexPrices)) {
            if ((v as any).usd) prices[key] = v;
        }
        return prices
    }
}





// export function useReferrerTier(library, chainId, account) {
//     const referralStorageAddress = getContract(chainId, "ReferralStorage");
//     const { data: referrerTier, mutate: mutateReferrerTier } = useSWR(
//         account && [`ReferralStorage:referrerTiers`, chainId, referralStorageAddress, "referrerTiers", account],
//         {
//             fetcher: contractFetcher(library, ReferralStorage),
//         }
//     );
//     return {
//         referrerTier,
//         mutateReferrerTier,
//     };
// }
