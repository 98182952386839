import { getCrvChefContract, getCvxChefContract, getCvxRegistry, getCvxStakingContract } from "domain/convex";
import { CVX_CHEF_STAKING_ABI, CRV_STAKING_ABI, CRV_CHEF_ABI, GAUGE_ABI, CVX_CHEF_ABI, CVX_REWARDS_STAKING_ABI, SUSHI_CHEF_ABI, CRV_SUSHI_STAKING_ABI, LOCK_CVX_ABI, registryAbi, SUSHI_CHEF_UNSTAKE_ABI , SUSHI_CHEF_CLAIM_ABI, CURVE_ABI, MINTER_ABI_2, MINTER_ABI, UNI_ABI } from "abis/Convex"

import { ethers } from "ethers";
import { MulticallRequestConfig } from "lib/multicall";
import { executeMulticall } from "lib/multicall/utils";
import { getProvider } from "lib/rpc";
import { FormatTypes, Interface } from "ethers/lib/utils";
import { getCoingeckoPrices } from "domain/convex";
let chainId = 1;

function getWithExpiry (key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

function setWithExpiry (key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

// imported#
function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - (i as any)).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

function getChainExplorerUrl(chain, address) {
    switch (chain) {
        case "eth":
            return `https://etherscan.io/token/${address}`;
        case "bsc":
            return `https://bscscan.com/token/${address}`;
        case "fantom":
            return `https://ftmscan.com/token/${address}`;
        case "hoo":
            return `https://hooscan.com/token/${address}`;
        case "evmos":
            return `https://evm.evmos.org/address/${address}`;
        case "astar":
            return `https://blockscout.com/astar/address/${address}`;
        case "kava":
            return `https://explorer.kava.io/token/${address}`;
        case "harmony":
            return `https://explorer.harmony.one/address/${address}`;
        case "arbitrum":
            return `https://arbiscan.io/address/${address}`;
        case "cronos":
            return `https://cronoscan.com/address/${address}`;
        case "moonbeam":
            return `https://moonscan.io/address/${address}`;
        case "moonriver":
            return `https://moonriver.moonscan.io/address/${address}`
        case "velas":
            return `https://evmexplorer.velas.com/address/${address}`;
        case "aurora":
            return `https://aurorascan.dev/token/${address}`;
        case "boba":
            return `https://blockexplorer.boba.network/address/${address}`;
        case "metis":
            return `https://andromeda-explorer.metis.io/address/${address}`;
        case "meter":
            return `https://scan.meter.io/address/${address}`;
        case "emerald":
            return `https://explorer.emerald.oasis.dev/token/${address}`;
        case "telos":
            return `https://www.teloscan.io/address/${address}`;
        case "matic":
            return `https://polygonscan.com/address/${address}`;
        case "milkomeda":
            return `https://explorer-mainnet-cardano-evm.c1.milkomeda.com/address/${address}`;
        case "dfk":
            return `https://subnets.avax.network/defi-kingdoms/dfk-chain/explorer/address/${address}`;
        case "avax":
            return `https://snowtrace.io/address/${address}`;
        case "optimism":
            return `https://optimistic.etherscan.io/address/${address}`;
    }
}

function getPoolPrices(tokens, prices, pool, chain = "eth") {
    // console.log(pool)
    // if (pool.w0 != null) return getValuePrices(tokens, prices, pool);
    // if (pool.buniPoolTokens != null) return getBunicornPrices(tokens, prices, pool);
    // if (pool.poolTokens != null) return getBalancerPrices(tokens, prices, pool, chain);
    // if (pool.isGelato) return getGelatoPrices(tokens, prices, pool, chain);
    // if (pool.token0 != null) return getUniPrices(tokens, prices, pool, chain);
    // if (pool.xcp_profit != null) return getTriCryptoPrices(prices, pool, chain);
    // if (pool.yearn) return getYearnPrices(prices, pool, chain);
    // if (pool.virtualPrice != null) return getCurvePrices(prices, pool, chain); //should work for saddle too
    // if (pool.token != null) return getWrapPrices(tokens, prices, pool, chain);
    // return getErc20Prices(prices, pool, chain);
    return getCurvePrices(prices, pool, chain)
}

function getCurvePrices(prices, pool, chain) {
    // console.log("pool", pool)
    var price;
    if (pool.token != undefined) {
        // console.log("pool", pool)
        if (pool.token.address == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"){ //ETH
            pool.token.address = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" //WETH
        }
        price = (getParameterCaseInsensitive(prices, pool.token.address)?.usd);
    } else {
        return {}
    }
    if (price) {
        price = price * pool.virtualPrice;
    } else {
        switch (pool.token.address) {
            case "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE": //ETH
                pool.token.address = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" //WETH
        }
        price = getPoolPrices(pool.token.tokens, prices, pool.token, chain).price * pool.virtualPrice;
    }
    if (getParameterCaseInsensitive(prices, pool.address)?.usd ?? 0 == 0) {
        prices[pool.address] = { usd: price };
    }
    // console.log("getCurvePrices, price", price)
    var tvl = pool.totalSupply * price / 10 ** pool.decimals;
    var staked_tvl = pool.staked * price;
    const poolUrl = getChainExplorerUrl(chain, pool.address);
    const name = `<a href='${poolUrl}' target='_blank'>${pool.symbol}</a>`;
    // const getDexguruTokenlink = function () {
    //     const network = window.location.pathname.split("/")[1]
    //     let dexguruTokenlink = '';
    //     if (tvl > 0) {
    //         if (network && (network.toLowerCase() === 'bsc' || network.toLowerCase() === 'eth' || network.toLowerCase() === 'polygon')) {
    //             dexguruTokenlink = `<a href='https://dex.guru/token/${pool.address.toLowerCase()}-${network.toLowerCase()}' rel='noopener' target='_blank'>[%]</a>`;
    //         }
    //     }
    //     return dexguruTokenlink
    // }

    return {
        name: name,
        staked_tvl: staked_tvl,
        price: price,
        stakeTokenTicker: pool.symbol,
        // print_price() {
        //     _print(`${name} Price: $${formatMoney(price)} Market Cap: $${formatMoney(tvl)} ${getDexguruTokenlink()}`);
        //     _print(`Staked: ${pool.staked.toFixed(4)} ${pool.symbol} ($${formatMoney(staked_tvl)})`);
        // },
        // print_contained_price() {
        // },
        tvl: tvl
    }
}


function getParameterCaseInsensitive(object, key) {
    return object[(Object.keys(object) as any)
        .find(k => k.toLowerCase() === key.toLowerCase())
    ];
}

async function getCurveToken(curve, address, stakingAddress, minterAddress, your_address) {
    // TODO SUSD not working here, the ABI has been swapped with MINTER_ABI_2 but the ethcall function doesnt populate get_virtual_price into the minter variable
    const SUSD = "0xA5407eAE9Ba41422680e2e00537571bcC53efBfD"
    let minterABI = minterAddress == SUSD ? MINTER_ABI_2 : MINTER_ABI;
    // console.log(minter, address)
    const minterRequests: MulticallRequestConfig<any> = {
        minter: {
            contractAddress: minterAddress,
            abi: minterABI,
            calls: {
                virtualPrice: { methodName: "get_virtual_price", params: [] },
                coin0: { methodName: "coins", params: [0] }
            },
        },
    };
    const minterCalls = await executeMulticall(chainId, undefined, minterRequests);
    const virtualPrice = minterCalls.minter.virtualPrice.returnValues[0]
    const coin0 = minterCalls.minter.coin0.returnValues[0]
    // const [virtualPrice, coin0] = await app.ethcallProvider.all([minter.get_virtual_price(), minter.coins(0)]);
    // console.log(virtualPrice, coin0)
    //@todo commented out getToken() HOLY SHIT ITS SO LONG
    // const token = await getToken(app, coin0, address);
    const token = { address: coin0 };

    // console.log(token)
    const curveRequests: MulticallRequestConfig<any> = {
        curve: {
            contractAddress: address,
            abi: CURVE_ABI,
            calls: {
                decimals: { methodName: "decimals", params: [] },
                staked: { methodName: "balanceOf", params: [stakingAddress] },
                unstaked: { methodName: "balanceOf", params: [your_address] },
                name: { methodName: "name", params: [] },
                symbol: { methodName: "symbol", params: [] },
                totalSupply: { methodName: "totalSupply", params: [] },
            },
        },
    };
    const curveCalls = await executeMulticall(chainId, undefined, curveRequests);
    // const calls = [curve.decimals(), curve.balanceOf(stakingAddress), curve.balanceOf(app.YOUR_ADDRESS), curve.name(), curve.symbol(), curve.totalSupply()];
    // const [decimals_, staked, unstaked, name, symbol, totalSupply] = await app.ethcallProvider.all(calls);
    const decimals_ = curveCalls.curve.decimals.returnValues[0];
    const staked = curveCalls.curve.staked.returnValues[0];
    const unstaked = curveCalls.curve.unstaked.returnValues[0];
    const name = curveCalls.curve.name.returnValues[0];
    const symbol = curveCalls.curve.symbol.returnValues[0];
    const totalSupply = curveCalls.curve.totalSupply.returnValues[0];
    const decimals = decimals_ / 1;
    return {
        address,
        name,
        symbol,
        totalSupply,
        decimals,
        staked: staked / 10 ** decimals,
        unstaked: unstaked / 10 ** decimals,
        contract: curve,
        tokens: [address, coin0],
        token,
        virtualPrice: virtualPrice / 1e18,
        minter: minterAddress
    };
}

export async function fetchConvexData(your_address: string) {
    // console.log(your_address)
    // const App = { YOUR_ADDRESS: "0x682ba005af4276749a26dc18400b395dac408191" }
    const provider = getProvider(undefined, chainId);
    // check if stored in localStorage
    const existingData = getWithExpiry("Convex");
    if (existingData) {
        console.log('convex already exists')
        // console.log(existingData)
        return existingData
    }
    const firing = getWithExpiry("ConvexFiring");
    if (firing){
        return existingData
    }
    setWithExpiry("ConvexFiring", "true", 5000)
    console.log("convex fired")

    const CRV_CHEF = getCrvChefContract(chainId);
    // const crvStakingPools = [
    //     "0x3Fe65692bfCD0e6CF84cB1E7d24108E434A7587e",
    //     "0xCF50b810E57Ac33B91dCF525C6ddd9881B139332"
    // ].map(a => {
    //     return {
    //         address: a,
    //         abi: CRV_STAKING_ABI,
    //         stakeTokenFunction: "stakingToken",
    //         rewardTokenFunction: "rewardToken"
    //     };
    // })

    // const crvStakingPool = {
    //     address: "0x7091dbb7fcbA54569eF1387Ac89Eb2a5C9F6d2EA",
    //     abi: CRV_STAKING_ABI,
    //     stakeTokenFunction: "rewardToken",
    //     rewardTokenFunction: "rewardToken"
    // }

    // const CVX_CHEF = getCvxChefContract(chainId);
    const CVX_CHEF_STAKING = getCvxStakingContract(chainId);
    // const block = await App.provider.getBlockNumber();
    const poolCount = await CVX_CHEF_STAKING.poolLength();
    // const rewardsPerWeek = rewardPerBlock / 1e18 * 604800 / 13.5 * multiplier;

    /*let cp = await loadChefContract(App, null, CVX_CHEF_ADDR, CVX_CHEF_ABI,
        "CVX", "cvx", null, rewardsPerWeek, "pendingCvx", [0]);*/
    // console.log(poolCount)
    // console.log(CRV_CHEF)
    const SUSHI_CHEF_ADDR = await CRV_CHEF.MASTER_CHEF();
    const SUSHI_CHEF = new ethers.Contract(SUSHI_CHEF_ADDR, SUSHI_CHEF_ABI, provider);

    const bonusMultiplier = await SUSHI_CHEF.BONUS_MULTIPLIER();
    const rewardsCrvPerWeek = await CRV_CHEF.sushiPerBlock() / 1e18 * 604800 / 13.5 * bonusMultiplier;
    const CRV_CHEF_ADDR = "0xEF0881eC094552b2e128Cf945EF17a6752B4Ec5d";
    let cp = await loadCrvContract(CRV_CHEF, CRV_CHEF_ADDR, CRV_CHEF_ABI, "SUSHI", rewardsCrvPerWeek, "pendingSushi", your_address);

    // const crvSushiPools = [
    //     "0x9e01aaC4b3e8781a85b21d9d9F848e72Af77B362",
    //     "0x1FD97b5E5a257B0B9b9A42A96BB8870Cbdd1Eb79"
    // ].map(a => {
    //     return {
    //         address: a,
    //         abi: CRV_SUSHI_STAKING_ABI,
    //         stakeTokenFunction: "stakingToken",
    //         rewardTokenFunction: "rewardToken"
    //     }
    // })

    let tokens = {};
    let prices = cp.prices;

    // let csp = await loadMultipleCrvSushiPools(App, tokens, cp.prices, crvSushiPools, cp)
    // _print_bold(`Total staked: $${formatMoney(csp.staked_tvl)}`);
    // if (csp.totalUserStaked > 0) {
    //   _print(`You are staking a total of $${formatMoney(csp.totalUserStaked)} at an APR of ${(csp.totalAPR * 100).toFixed(2)}%\n`);
    // }
    const allPoolsRequest: MulticallRequestConfig<any> = {
        cvx_chef_calls: {
            contractAddress: CVX_CHEF_STAKING.address,
            abi: CVX_CHEF_STAKING_ABI,
            calls: { 
                // name: { methodName: "name", params: [] }
            },
        },
    };
    [...Array(poolCount / 1).keys()].forEach(i => {
        allPoolsRequest.cvx_chef_calls.calls[i] = { methodName: "poolInfo", params: [i] }
    })
    const cvxCalls = await executeMulticall(chainId, undefined, allPoolsRequest);
    const poolInfos = [...Array(poolCount / 1).keys()].map(i => {
        return { 
            lptoken: cvxCalls.cvx_chef_calls[i].returnValues[0],
            token: cvxCalls.cvx_chef_calls[i].returnValues[1],
            gauge: cvxCalls.cvx_chef_calls[i].returnValues[2],
            crvRewards: cvxCalls.cvx_chef_calls[i].returnValues[3]
        }
    });
    // console.log(poolInfos)

    // // we will only show these synthetic pools on convex
    // const stETH = "0x828b154032950C8ff7CF8085D841723Db2696056";
    const stETH = "0x06325440D014e39736583c165C2963BA99fAf14E";
    const frxETH = "0xf43211935C781D5ca1a41d2041F397B8A7366C7A";
    const weth_stETH = "0x828b154032950C8ff7CF8085D841723Db2696056";
    const alETH = "0xC4C319E2D4d66CcA4464C0c2B32c9Bd23ebe784e";
    const sETH = "0xA3D87FffcE63B53E0d54fAa1cc983B7eB0b74A9c";
    const rETH = "0x6c38cE8984a890F5e46e6dF6117C26b3F1EcfC9C";
    const cbETH = "0x5b6C539b224014A09B3388e51CaAA8e354c959C8";

    const GUSD = "0xD2967f45c4f384DEEa880F807Be904762a3DeA07";
    const MUSD = "0x1AEf73d49Dedc4b1778d0706583995958Dc862e6";
    // const SUSD = "0xC25a3A3b969415c80451098fa907EC722572917F"; // TODO getCurveToken() not working for SUSD
    const BUSD = "0x4807862AA8b2bF68830e4C8dc86D0e9A998e085a";
    const LUSD = "0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA";
    // const EURS = "0x3D229E1B4faab62F621eF2F6A610961f7BD7b23B"; // TODO not working
    // const LpTokenPools = [GUSD, MUSD, BUSD, LUSD, stETH]
    const LpTokenPools = [stETH, frxETH, weth_stETH, alETH, sETH, rETH, cbETH]
    // const LpTokenPools = [stETH, frxETH, cbETH]
    const Pools = poolInfos
        .filter(pi => LpTokenPools.includes(pi.lptoken))
        .map(pi => {
            return {
                gauge: pi.gauge,
                lptoken: pi.lptoken,
                abi: CVX_REWARDS_STAKING_ABI,
                rewardContractAddr: pi.crvRewards,
                gaugeAbi: GAUGE_ABI
            }
        });

    // const LockCvxPool = {
    //     address: "0xD18140b4B819b895A3dba5442F959fA44994AF50",
    //     abi: LOCK_CVX_ABI,
    //     stakeTokenFunction: "stakingToken",
    //     rewardTokenFunction: "rewardTokens"
    // }

    // _print("This is a locking pool. Please check the website for more details")
    // _print(`Lock CVX for 16 weeks + 7 days. Locked CVX will earn platform fees as well as give voting weight for proposal and gauge weight voting.`)
    // _print("");
    // let p2 = await loadLockCvxSynthetixPool(App, tokens, prices, LockCvxPool.abi, LockCvxPool.address, LockCvxPool.rewardTokenFunction, LockCvxPool.stakeTokenFunction);
    // _print_bold(`Total staked: $${formatMoney(p2.staked_tvl)}`);
    // if (p2.totalUserStaked > 0) {
    //   _print(`You are staking a total of $${formatMoney(p2.totalUserStaked)} at an APR of ${((p2.totalAPR) / (p2.totalUserStaked) * 100).toFixed(2)}%\n`);
    // }
    // _print("");

    // let p0 = await loadMultipleSynthetixPools(App, tokens, prices, crvStakingPools);

    // let p1 = await loadMultipleSynthetixPools(App, tokens, prices, [crvStakingPool]);
    // console.log(Pools)


    let p = await loadMultipleConvexSynthetixPools(tokens, prices, Pools, your_address);
    setWithExpiry("Convex", p, 60000)
    console.log(p)
    return p;
}

async function loadMultipleConvexSynthetixPools(tokens, prices, pools, your_address) {
    const registry = getCvxRegistry(chainId);
    let totalStaked = 0, totalUserStaked = 0, individualAPRs: any = [];
    const infos = await Promise.all(pools.map(async p => {
        try {
            return await loadConvexSynthetixPoolInfo(tokens, prices, p, registry, your_address);
        }
        catch (err) {
            console.log(p.lptoken, err);
            return null;
        }
    }));
    const lpStakeTokens = infos.map(i => i?.lpStakeToken?.tokens[1]);
    let newPriceAddresses = lpStakeTokens.filter(x => x && !getParameterCaseInsensitive(prices, x));
    let newPrices = await getCoingeckoPrices(newPriceAddresses);
    // console.log("newPrices", newPrices)
    for (const key in newPrices) {
        if (newPrices[key])
            prices[key] = newPrices[key];
    }
    let poolData: any = [];
    // console.log(lpStakeTokens, infos)
    // console.log("prices", prices)
    for (const i of infos) {
        // if (i "0x4606326b4Db89373F5377C316d3b0F6e55Bc6A20")
        // convert ETH to WETH for pricing reasons
        // if (!i || i.lpStakeToken.tokens[1] === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") continue;
        // let p = await printConvexSynthetixPool(App, tokens, prices, i);
        let p = await getConvexSynthetixPoolData(tokens, prices, i)
        poolData.push({...p, lp: i.lpStakeToken, });

        totalStaked += p.staked_tvl || 0;
        totalUserStaked += p.userStaked || 0;
        if (p.userStaked > 0) {
            individualAPRs.push(p.userStaked * p.apr / 100);
        }
    }
    let totalAPR = totalUserStaked === 0 ? 0 : individualAPRs.reduce((x, y) => x + y, 0) / totalUserStaked;
    return { staked_tvl: totalStaked, totalUserStaked, totalAPR, poolData };
}

async function loadConvexSynthetixPoolInfo(tokens, prices, pool, registry, your_address) {


    const provider = getProvider(undefined, chainId);
    

    const rewardContractAddr = pool.rewardContractAddr

    const lpToken = pool.lptoken;

    const minterABI = [
        "function minter() view returns (address)"
    ]
    const minterFace = new Interface(minterABI);

    // const lpTokenContract = new ethers.Contract(lpToken, minterFace, provider);

    let multicallReq: MulticallRequestConfig<any> = {
        lpMinter: {
            contractAddress: lpToken,
            abi: JSON.parse(minterFace.format(FormatTypes.json) as string),
            // abi: JSON.parse(registry.interface.format(FormatTypes.json)),
            calls: {
                minter: { methodName: "minter", params: [] }
            },
        },
        // registry: {
        //     contractAddress: registry.address,
        //     abi: JSON.parse(registry.interface.format(FormatTypes.json)),
        //     calls: {
        //         get_pool_from_lp_token: { methodName: "get_pool_from_lp_token", params: [lpToken] }
        //     },
        // },
        STAKING_MULTI: {
            contractAddress: pool.rewardContractAddr,
            abi: pool.abi,
            calls: {
                periodFinish: { methodName: "periodFinish", params: [] },
                rewardRate: { methodName: "rewardRate", params: [] },
                balanceOf: { methodName: "balanceOf", params: [your_address] },
                earned: { methodName: "earned", params: [your_address] },
                stakingToken: { methodName: "stakingToken", params: [] },
                rewardToken: { methodName: "rewardToken", params: [] },
                totalSupply: { methodName: "totalSupply", params: [] }
            },
        }
    };

    //@audit hacky mchackerson bc i cba to deal with curve
    const hackyTokens = ["0x828b154032950C8ff7CF8085D841723Db2696056", "0xC4C319E2D4d66CcA4464C0c2B32c9Bd23ebe784e"] //aleth & smol stETH
    if (hackyTokens.includes(lpToken)) {
        delete multicallReq.lpMinter;
    }

    // const calls = [registry.get_pool_from_lp_token(lpToken), STAKING_MULTI.periodFinish(), STAKING_MULTI.rewardRate(),
    // STAKING_MULTI.balanceOf(your_address), STAKING_MULTI.earned(your_address),
    // STAKING_MULTI.stakingToken(), STAKING_MULTI.rewardToken(), STAKING_MULTI.totalSupply()]
    // const [minter, periodFinish, rewardRate, balance, earned_, stakeTokenAddress, rewardTokenAddress, totalSupply] = await App.ethcallProvider.all(calls);

    const mc = await executeMulticall(chainId, undefined, multicallReq);
    let minter;
    // @todo this doesnt work if lp token & pool token are joint together
    if (hackyTokens.includes(lpToken)) {
        minter = lpToken;
    } else {
        minter = mc.lpMinter.minter.returnValues[0];
    }
    // const minter = mc.registry.get_pool_from_lp_token.returnValues[0];
    // console.log("minter", minter)
    const periodFinish = mc.STAKING_MULTI.periodFinish.returnValues[0];
    const rewardRate = mc.STAKING_MULTI.rewardRate.returnValues[0];
    const balance = mc.STAKING_MULTI.balanceOf.returnValues[0];
    const earned_ = mc.STAKING_MULTI.earned.returnValues[0];
    const stakeTokenAddress = mc.STAKING_MULTI.stakingToken.returnValues[0];
    const rewardTokenAddress = mc.STAKING_MULTI.rewardToken.returnValues[0];
    const totalSupply = mc.STAKING_MULTI.totalSupply.returnValues[0];

    const curve = new ethers.Contract(lpToken, JSON.stringify(CURVE_ABI), provider);
    // TODO getCurveToken() not working for SUSD
    // console.log(lpToken, minter)
    let lpStakeToken = await getCurveToken(curve, lpToken, rewardContractAddr, minter, your_address);
    lpStakeToken.staked = totalSupply / 10 ** lpStakeToken.decimals;

    //@audit replaced getToken() & manually called symbol & decimals
    if (!getParameterCaseInsensitive(tokens, rewardTokenAddress)) {
        const rewardTokenReq: MulticallRequestConfig<any> = {
            curve: {
                contractAddress: rewardTokenAddress,
                abi: CURVE_ABI,
                calls: {
                    decimals: { methodName: "decimals", params: [] },
                    symbol: { methodName: "symbol", params: [] }
                },
            },
        };
        const rewardTokenCalls = await executeMulticall(chainId, undefined, rewardTokenReq);
        tokens[rewardTokenAddress] = {}
        tokens[rewardTokenAddress].decimals = rewardTokenCalls.curve.decimals.returnValues[0];
        tokens[rewardTokenAddress].symbol = rewardTokenCalls.curve.symbol.returnValues[0];
    }
    const rewardToken = getParameterCaseInsensitive(tokens, rewardTokenAddress);

    // console.log(rewardToken)
    // console.log(prices)
    
    const rewardTokenTicker = rewardToken.symbol;

    const stakeTokenPrice =
        prices[stakeTokenAddress]?.usd ?? getParameterCaseInsensitive(prices, stakeTokenAddress)?.usd;
    const rewardTokenPrice = getParameterCaseInsensitive(prices, rewardTokenAddress)?.usd;

    const weeklyRewards = (Date.now() / 1000 > periodFinish) ? 0 : rewardRate / 1e18 * 604800;

    const usdPerWeek = weeklyRewards * rewardTokenPrice;

    const userStaked = balance / 10 ** lpStakeToken.decimals;

    const userUnstaked = lpStakeToken.unstaked;

    const earned = earned_ / 10 ** rewardToken.decimals;

    return {
        lpStakeToken,
        lpToken,
        rewardContractAddr,
        stakeTokenAddress,
        rewardTokenAddress,
        rewardTokenTicker,
        stakeTokenPrice,
        rewardTokenPrice,
        weeklyRewards,
        usdPerWeek,
        userStaked,
        userUnstaked,
        earned
    }
}

async function getConvexSynthetixPoolData(tokens, prices, info) {
    const poolPrices = getPoolPrices(tokens, prices, info.lpStakeToken);
    // console.log(poolPrices)
    const stakeTokenTicker = poolPrices.stakeTokenTicker;
    const staked_tvl = poolPrices.staked_tvl;
    const name = poolPrices.name
    const price = poolPrices.price;
    // const earningsPerWeek = formatMoney(info.usdPerWeek);
    // poolPrices.print_price(chain, 4, customURLs);
    // _print(`${info.rewardTokenTicker} Per Week: ${info.weeklyRewards.toFixed(2)} ($${formatMoney(info.usdPerWeek)})`);
    const weeklyAPR = info.usdPerWeek / (staked_tvl as number) * 100;
    // const dailyAPR = weeklyAPR / 7;
    const yearlyAPR = weeklyAPR * 52;
    // _print(`APR: Day ${dailyAPR.toFixed(2)}% Week ${weeklyAPR.toFixed(2)}% Year ${yearlyAPR.toFixed(2)}%`);
    const userStakedUsd = info.userStaked * poolPrices.price;
    const userStakedPct = userStakedUsd / (staked_tvl as number) * 100;
    const stakedTokens = info.userStaked.toFixed(2);
    const stakedValue = formatMoney(userStakedUsd);
    // _print(`You are staking ${info.userStaked.toFixed(6)} ${stakeTokenTicker} ` +
    //   `$${formatMoney(userStakedUsd)} (${userStakedPct.toFixed(2)}% of the pool).`);
    // if (info.userStaked > 0) {
    //   info.poolPrices?.print_contained_price(info.userStaked);
    const userWeeklyRewards = userStakedPct * info.weeklyRewards / 100;
    // const userDailyRewards = userWeeklyRewards / 7;
    // const userYearlyRewards = userWeeklyRewards * 52;
    //   _print(`Estimated ${info.rewardTokenTicker} earnings:`
    //     + ` Day ${userDailyRewards.toFixed(2)} ($${formatMoney(userDailyRewards * info.rewardTokenPrice)})`
    //     + ` Week ${userWeeklyRewards.toFixed(2)} ($${formatMoney(userWeeklyRewards * info.rewardTokenPrice)})`
    //     + ` Year ${userYearlyRewards.toFixed(2)} ($${formatMoney(userYearlyRewards * info.rewardTokenPrice)})`);
    // }
    // const approveTENDAndStake = async function () {
    //     return rewardsContract_stake(info.stakeTokenAddress, info.stakingAddress, App)
    // }
    // const unstake = async function () {
    //     return rewardsContract_unstake(info.stakingAddress, App)
    // }
    // const claim = async function () {
    //     return rewardsContract_claim(info.stakingAddress, App)
    // }
    // const exit = async function () {
    //     return rewardsContract_exit(info.stakingAddress, App)
    // }
    // const revoke = async function () {
    //     return rewardsContract_resetApprove(info.stakeTokenAddress, info.stakingAddress, App)
    // }
    // _print(`<a target="_blank" href="https://etherscan.io/address/${info.stakingAddress}#code">Etherscan</a>`);
    // if (stakeTokenTicker != "ETH") {
    //   _print_link(`Stake ${info.userUnstaked.toFixed(6)} ${stakeTokenTicker}`, approveTENDAndStake)
    // }
    // else {
    //   _print("Please use the official website to stake ETH.");
    // }
    // _print_link(`Unstake ${info.userStaked.toFixed(6)} ${stakeTokenTicker}`, unstake)
    // _print_link(`Claim ${info.earned.toFixed(6)} ${info.rewardTokenTicker} ($${formatMoney(info.earned * info.rewardTokenPrice)})`, claim)
    // if (stakeTokenTicker != "ETH") {
    //   _print_link(`Revoke (set approval to 0)`, revoke)
    // }
    // _print_link(`Exit`, exit)
    // _print("");

    return {
        userStaked: userStakedUsd,
        staked_tvl,
        earned: formatMoney(info.earned * info.rewardTokenPrice),
        stakeTokenTicker,
        name,
        price,
        earningsPerWeek: formatMoney(userWeeklyRewards * info.rewardTokenPrice),
        apr: yearlyAPR,
        stakedValue,
        stakedTokens,
        // approveTENDAndStake,
        // unstake,
        // claim,
        // revoke
    }
}




async function loadCrvContract(chef, chefAddress, chefAbi, rewardTokenFunction, rewardsPerWeekFixed, pendingRewardsFunction, your_address) {
    const provider = getProvider(undefined, chainId);
    const chefContract = chef ?? new ethers.Contract(chefAddress, chefAbi, provider);

    const poolCount = 2;
    const totalAllocPoints = await chefContract.totalAllocPoint();

    const tokens = {};

    const rewardTokenAddress = await chefContract.callStatic[rewardTokenFunction]();
    const rewardsPerWeek = rewardsPerWeekFixed;

    let poolInfos: any = [];
    for (let i = 1; i <= poolCount; i++) {
        const poolInfo = await getCrvPoolInfo(your_address, chefContract, chefAddress, i, pendingRewardsFunction, totalAllocPoints, rewardsPerWeek, rewardTokenAddress);
        poolInfos.push(poolInfo);
    }
    // console.log(poolInfos)

    let tokenAddresses = [].concat.apply([], poolInfos.filter(x => x?.poolToken).map(x => x.poolToken.tokens));
    // console.log(tokenAddresses)
    let newTokenAddresses = tokenAddresses.concat([(rewardTokenAddress as never)]);
    const prices = await getCoingeckoPrices(newTokenAddresses);
    // console.log("cg_prices", prices)

    // await Promise.all(tokenAddresses.map(async (address) => {
    //     tokens[address] = await getToken(App, address, chefAddress);
    // }));

    return { prices, poolInfos }
}

async function getCrvPoolInfo(your_address, chefContract, chefAddress, poolIndex, pendingRewardsFunction, totalAllocPoints, rewardsPerWeek, rewardTokenAddress) {
    const provider = getProvider(undefined, chainId);
    const poolInfo = await chefContract.poolInfo(poolIndex);
    const poolRewardsPerWeek = poolInfo.allocPoint / totalAllocPoints * rewardsPerWeek;
    const lpToken = await chefContract.lpToken(poolIndex);
    // console.log(lpToken, chefAddress)
    // const poolToken = await getToken(app, lpToken, chefAddress);
    const uniPool = new ethers.Contract(lpToken, UNI_ABI, provider);
    const [token0, token1, decimals] = await Promise.all([
        uniPool.token0(),
        uniPool.token1(),
        uniPool.decimals()
    ])
    const poolToken = { tokens: [token0, token1], decimals }
    const userInfo = await chefContract.userInfo(poolIndex, your_address);
    const pendingRewardTokens = await chefContract.callStatic[pendingRewardsFunction](poolIndex, your_address);
    const staked = userInfo.amount / 10 ** poolToken.decimals;
    // const rewardToken = await getToken(app, rewardTokenAddress, chefAddress);
    return {
        address: lpToken,
        poolRewardsPerWeek: poolRewardsPerWeek,
        poolToken: poolToken,
        userStaked: staked,
        pendingRewardTokens: pendingRewardTokens / 10 ** 18,
        lastRewardBlock: poolInfo.lastRewardBlock,
        // rewardToken: rewardToken,
        rewardTokenTicker: "SUSHI",
        rewardPoolTokenAddress: rewardTokenAddress
    };
}