// import React from "react";
import Footer from "components/Footer/Footer";
import "./Home.css";

import simpleSwapIcon from "img/ic_simpleswaps.svg";
// import costIcon from "img/ic_cost.svg";
import liquidityIcon from "img/ic_liquidity.svg";
import totaluserIcon from "img/ic_totaluser.svg";

import statsIcon from "img/ic_stats.svg";
import tokensIcon from "img/ic_tokens.svg";
import tradingIcon from "img/ic_trading.svg";

// import useSWR from "swr";

// import { USD_DECIMALS, getTotalVolumeSum } from "lib/legacy";

// import { useUserStat } from "domain/legacy";

// import arbitrumIcon from "img/ic_arbitrum_96.svg";
// import avaxIcon from "img/ic_avalanche_96.svg";

// import TokenCard from "components/TokenCard/TokenCard";
import { Trans } from "@lingui/macro";
import { HeaderLink } from "components/Header/HeaderLink";
// import { ARBITRUM, AVALANCHE } from "config/chains";
// import { getServerUrl } from "config/backend";
// import { bigNumberify, formatAmount, numberWithCommas } from "lib/numbers";

export default function Home({ showRedirectModal, redirectPopupTimestamp }) {
  // const [openedFAQIndex, setOpenedFAQIndex] = useState(null)
  // const faqContent = [{
  //   id: 1,
  //   question: "What is GMX?",
  //   answer: "GMX is a decentralized spot and perpetual exchange that supports low swap fees and zero price impact trades.<br><br>Trading is supported by a unique multi-asset pool that earns liquidity providers fees from market making, swap fees, leverage trading (spreads, funding fees & liquidations), and asset rebalancing.<br><br>Dynamic pricing is supported by Chainlink Oracles along with TWAP pricing from leading volume DEXs."
  // }, {
  //   id: 2,
  //   question: "What is the GMX Governance Token? ",
  //   answer: "The GMX token is the governance token of the GMX ecosystem, it provides the token owner voting rights on the direction of the GMX platform.<br><br>Additionally, when GMX is staked you will earn 30% of the platform-generated fees, you will also earn Escrowed GMX tokens and Multiplier Points."
  // }, {
  //   id: 3,
  //   question: "What is the GLP Token? ",
  //   answer: "The GLP token represents the liquidity users provide to the GMX platform for Swaps and Margin Trading.<br><br>To provide liquidity to GLP you <a href='https://gmx.io/buy_glp' target='_blank'>trade</a> your crypto asset BTC, ETH, LINK, UNI, USDC, USDT, MIM, or FRAX to the liquidity pool, in exchange, you gain exposure to a diversified index of tokens while earning 50% of the platform trading fees and esGMX."
  // }, {
  //   id: 4,
  //   question: "What can I trade on GMX? ",
  //   answer: "On GMX you can swap or margin trade any of the following assets: ETH, BTC, LINK, UNI, USDC, USDT, MIM, FRAX, with others to be added. "
  // }]

  // const toggleFAQContent = function(index) {
  //   if (openedFAQIndex === index) {
  //     setOpenedFAQIndex(null)
  //   } else {
  //     setOpenedFAQIndex(index)
  //   }
  // }

  // ARBITRUM

  // const arbitrumPositionStatsUrl = getServerUrl(ARBITRUM, "/position_stats");
  // const { data: arbitrumPositionStats } = useSWR([arbitrumPositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const arbitrumTotalVolumeUrl = getServerUrl(ARBITRUM, "/total_volume");
  // const { data: arbitrumTotalVolume } = useSWR([arbitrumTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // // AVALANCHE

  // const avalanchePositionStatsUrl = getServerUrl(AVALANCHE, "/position_stats");
  // const { data: avalanchePositionStats } = useSWR([avalanchePositionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const avalancheTotalVolumeUrl = getServerUrl(AVALANCHE, "/total_volume");
  // const { data: avalancheTotalVolume } = useSWR([avalancheTotalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // // Total Volume

  // const arbitrumTotalVolumeSum = getTotalVolumeSum(arbitrumTotalVolume);
  // const avalancheTotalVolumeSum = getTotalVolumeSum(avalancheTotalVolume);

  // let totalVolumeSum = bigNumberify(0);
  // if (arbitrumTotalVolumeSum && avalancheTotalVolumeSum) {
  //   totalVolumeSum = totalVolumeSum.add(arbitrumTotalVolumeSum);
  //   totalVolumeSum = totalVolumeSum.add(avalancheTotalVolumeSum);
  // }

  // // Open Interest

  // let openInterest = bigNumberify(0);
  // if (
  //   arbitrumPositionStats &&
  //   arbitrumPositionStats.totalLongPositionSizes &&
  //   arbitrumPositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(arbitrumPositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(arbitrumPositionStats.totalShortPositionSizes);
  // }

  // if (
  //   avalanchePositionStats &&
  //   avalanchePositionStats.totalLongPositionSizes &&
  //   avalanchePositionStats.totalShortPositionSizes
  // ) {
  //   openInterest = openInterest.add(avalanchePositionStats.totalLongPositionSizes);
  //   openInterest = openInterest.add(avalanchePositionStats.totalShortPositionSizes);
  // }

  // // user stat
  // const arbitrumUserStats = useUserStat(ARBITRUM);
  // const avalancheUserStats = useUserStat(AVALANCHE);
  // let totalUsers = 0;

  // if (arbitrumUserStats && arbitrumUserStats.uniqueCount) {
  //   totalUsers += arbitrumUserStats.uniqueCount;
  // }

  // if (avalancheUserStats && avalancheUserStats.uniqueCount) {
  //   totalUsers += avalancheUserStats.uniqueCount;
  // }

  const LaunchExchangeButton = () => {
    return (
      <HeaderLink
        className="default-btn"
        to="/positions"
        // redirectPopupTimestamp={redirectPopupTimestamp}
        // showRedirectModal={showRedirectModal}
      >
        <Trans>Launch App</Trans>
      </HeaderLink>
    );
  };

  return (
    <div className="Home">
      <div className="Home-top">
        {/* <div className="Home-top-image"></div> */}
        <div className="Home-title-section-container default-container">
          <div className="Home-title-section">
            <div className="Home-title">
              <Trans>
                DeFi's Smart
                <br />
                Keeper Service
              </Trans>
            </div>
            <div className="Home-description">
              <Trans>
                Neutralise DeFi native risks through 24/7 risk monitoring and permissioned transaction execution.
                {/* conduct continuous on-chain monitoring as part of our risk management process */}
              </Trans>
            </div>
            <LaunchExchangeButton />
          </div>
        </div>
        <div className="Home-latest-info-container default-container">
          <div className="Home-latest-info-block">
            <img src={tradingIcon} alt="Total Trading Volume Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Detection</Trans>
              </div>
              <Trans>
                Detect risks early with automated monitoring of on-chain risk vectors.
              </Trans>
            </div>
          </div>
          <div className="Home-latest-info-block">
            <img src={tokensIcon} alt="Open Interest Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Execution</Trans>
              </div>
              <Trans>
                Automated risk mitigation through lightning fast transaction execution bots.
                {/* Access to on-chain alerts for monitoring collateral price, trading adjustments & risk events. */}
              </Trans>
            </div>
          </div>
          <div className="Home-latest-info-block">
            <img src={totaluserIcon} alt="Total Users Icon" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">
                <Trans>Control Panel</Trans>
              </div>
              <Trans>
                View and “gaslessly” manage all your cross-chain DeFi assets in one place.
                {/* Access to sensitive functions are ringfenced to be compliant with legacy instituional solutions. */}
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-benefits-section">
        <div className="Home-benefits default-container">
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={liquidityIcon} alt="Reduce Liquidation Risks Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <Trans>DeFi Universe</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>
                Aid your selection of DeFi positions by selecting from a universe of risk vetted DeFi opportunities.
              </Trans>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={statsIcon} alt="Save on Costs Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <Trans>Simulation Engine</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>
                Simulate the effect of likely future transactions that could result in risk threshold violation, proactively taking action before the potential risk materialises.
              </Trans>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={simpleSwapIcon} alt="Simple Swaps Icon" className="Home-benefit-icon-symbol" />
              <div className="Home-benefit-title">
                <Trans>Atomic Transactions</Trans>
              </div>
            </div>
            <div className="Home-benefit-description">
              <Trans>
                Adjust your complex DeFi position in a single transaction by bundling multiple transactions into one.
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-cta-section">
        <div className="Home-cta-container default-container">
          <div className="Home-cta-info">
            <div className="Home-cta-info__title">
              <Trans>Solving Institutional DeFi Problems</Trans>
            </div>
            <div className="Home-cta-info__description">
              <Trans>
                Current retail focused DeFi solutions do not meet the immediate risk mitigation and access control layering that institutions require. 
                <br></br>
                <br></br>
                Through outsourcing the technical overhead of implementing the above, our clients can focus on their core business.</Trans>
            </div>
          </div>
          <div className="Home-cta-options">
            <div class="Home-token-card-option" style={{ marginTop: "2.325rem" }}>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-icon">Immediate Risk Mitigation</div>
                <div className="">
                  <Trans>
                    Delays in risk identification and subsequent response can lead to substantial loss. Identifying risk events from news outlets and social media is most likely too late to protect assets. Through immediate risk identification and response, the risk level of holding any crypto asset is massively reduced.</Trans>
                </div>
              </div>
            </div>
            <div class="Home-token-card-option" style={{ marginTop: "2.325rem" }}>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-icon">Reliability</div>
                <div className="">
                  <Trans>
                    Our transaction execution bot manages the full lifecycle of your transactions, catering for failures, gas spikes, duplicate transactions, malformations and more, resulting in a reliable execution service.</Trans>
                </div>
              </div>
            </div>
            <div class="Home-token-card-option" style={{ marginTop: "2.325rem" }}>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-icon">Access Control</div>
                <div className="">
                  <Trans>The execution bot's access control is ringfenced to ensure there is never access to client funds. Utilising best-in-class permission management architecture, approved interactions are whitelisted at the smart contract function & parameter level. With a steadfast commitment to minimising risk and safeguarding your investments, we offer unmatched security for ultimate peace of mind.</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-token-card-section">
        <div className="Home-token-card-container default-container">
          <div className="Home-token-card-info___">
            <div className="Home-token-card-info__title">
              <Trans>Getting Started</Trans>
            </div>
          </div>
          
          <div className="Home-token-card-options">
            <div className="Home-token-card-option">
              <div className="Home-token-card-option-icon">
                {/* <img src={gmxIcon} width="40" alt="GMX Icons" /> GMX */}
                1. Get in touch
              </div>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-title">
                  <Trans>Contact our team to explore how our services can benefit your business.</Trans>
                </div>
              </div>
            </div>
            <div className="Home-token-card-option">
              <div className="Home-token-card-option-icon">
                2. Demo & Discovery
              </div>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-title">
                  <Trans>Schedule a demo to discover the features of our services.</Trans>
                </div>
              </div>
            </div>
          </div>
          
          <div className="Home-token-card-options" style={{ marginTop: "2.325rem" }}>
            <div className="Home-token-card-option">
              <div className="Home-token-card-option-icon">
                3. Free Trial Onboarding
              </div>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-title">
                  <Trans>Onboarding to the free trial and receive guidance through-out the initial process.</Trans>
                </div>
              </div>
            </div>
            <div className="Home-token-card-option">
              <div className="Home-token-card-option-icon">
                4. Production
              </div>
              <div className="Home-token-card-option-info">
                <div className="Home-token-card-option-title">
                  <Trans>Transition smoothly from trial to full production with our comprehensive support and implementation services.</Trans>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="referral-card section-center mt-medium" style={{ marginTop: "6.325rem" }}>
          <h2 className="title">
            <Trans>{"Get Access"}</Trans>
          </h2>
          <p className="sub-title">
            <Trans>Start your journey with us.</Trans>
          </p>
          <div className="card-action">
            {
              <a href="mailto:admin@0x27.co.uk">
                <button
                  className="App-cta Exchange-swap-button"
                  style={{ marginBottom: "10px" }}
                  // disabled={account}
                  type="submit"
                // onClick={connectWallet}
                >
                  <Trans>
                    Email Us
                    {/* {account
                    ? "Logged into " + (ensName || shortenAddress(account, breakpoint === "S" ? 9 : 13))
                    : "Connect Wallet"} */}
                  </Trans>
                </button>
              </a>
            }
          </div>
        </div>
      </div>

      {/* <div className="Home-video-section">
        <div className="Home-video-container default-container">
          <div className="Home-video-block">
            <img src={gmxBigIcon} alt="gmxbig" />
          </div>
        </div>
      </div> */}
      {/* <div className="Home-faqs-section">
        <div className="Home-faqs-container default-container">
          <div className="Home-faqs-introduction">
            <div className="Home-faqs-introduction__title">FAQs</div>
            <div className="Home-faqs-introduction__description">Most asked questions. If you wish to learn more, please head to our Documentation page.</div>
            <a href="https://gmxio.gitbook.io/gmx/" className="default-btn Home-faqs-documentation">Documentation</a>
          </div>
          <div className="Home-faqs-content-block">
            {
              faqContent.map((content, index) => (
                <div className="Home-faqs-content" key={index} onClick={() => toggleFAQContent(index)}>
                  <div className="Home-faqs-content-header">
                    <div className="Home-faqs-content-header__icon">
                      {
                        openedFAQIndex === index ? <FiMinus className="opened" /> : <FiPlus className="closed" />
                      }
                    </div>
                    <div className="Home-faqs-content-header__text">
                      { content.question }
                    </div>
                  </div>
                  <div className={ openedFAQIndex === index ? "Home-faqs-content-main opened" : "Home-faqs-content-main" }>
                    <div className="Home-faqs-content-main__text">
                      <div dangerouslySetInnerHTML={{__html: content.answer}} >
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}
      <Footer showRedirectModal={showRedirectModal} redirectPopupTimestamp={redirectPopupTimestamp} />
    </div>
  );
}
