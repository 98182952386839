
import { Trans, t } from "@lingui/macro";

function PayWall() {
    // const { account, library, chainId } = useWeb3React();
    return (
        <div className="referral-card section-center mt-medium">
            <h2 className="title">
                <Trans>{"Get Access"}</Trans>
            </h2>
            <p className="sub-title">
                <Trans>You don't have access to 0x27 infrastructure.</Trans>
            </p>
            <div className="card-action">
                {
                    <a href="mailto:admin@0x27.co.uk">
                        <button
                            className="App-cta Exchange-swap-button"
                            style={{ marginBottom: "10px" }}
                            // disabled={account}
                            type="submit"
                        // onClick={connectWallet}
                        >
                            <Trans>
                                Email for Access
                                {/* {account
                    ? "Logged into " + (ensName || shortenAddress(account, breakpoint === "S" ? 9 : 13))
                    : "Connect Wallet"} */}
                            </Trans>
                        </button>
                    </a>
                }
            </div>
        </div>
    );
}
export default PayWall;
